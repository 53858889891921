import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconAngle from '@src/components/Icon/set/IconAngle';
import Icon from '@src/components/Icon/icon';
import { PointerIcon } from '@src/components/Icon';
import css from './style.module.scss';

const iconsSet = {
	pointer: (props) => <PointerIcon width={null} height={null} {...props} />,
	rightArrow: (props) => <IconAngle width={null} height={null} {...props} />,
};

const IconLink = React.forwardRef((props, ref) => {
	const {
		children,
		rightIcon,
		rightIconProps,
		state,
		iconMix,
		type,
		...rest
	} = props;
	const RightIcon = rightIcon ? iconsSet[rightIcon] : null;

	return (
		<a
			{...rest}
			ref={ref}
			className={cn(rest.className, css.block, {
				[css[`block_${state}`]]: !!state,
				[css[`block_type_${type}`]]: !!type,
			})}
		>
			<span className={cn(css.linkText)}>{children}</span>
			{RightIcon && (
				<RightIcon
					{...rightIconProps}
					className={cn(css.rightIcon, iconMix, {
						[css[`rightIcon_${rightIcon}`]]: !!rightIcon,
					})}
				/>
			)}
		</a>
	);
});

IconLink.propTypes = {
	iconMix: PropTypes.string,
	state: PropTypes.oneOf(['small']),
	rightIcon: PropTypes.oneOf(['pointer', 'rightArrow']),
	rightIconProps: PropTypes.shape(Icon.propTypes),
	type: PropTypes.oneOf(['thin']),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

IconLink.defaultProps = {
	state: undefined,
	iconMix: undefined,
	rightIcon: undefined,
	type: undefined,
	rightIconProps: undefined,
	children: undefined,
};

export default IconLink;
