import React from 'react';

export const getRisizeQuery = (params) => {
	const {
		width,
		height,
		cropFactor = 'n',
		quality = 95,
		cachedInDays = 31,
		imageFormat = 'rj',
	} = params;

	const queryParams = [];

	if (width) {
		queryParams.push(`w${Math.round(width)}`);
	}
	if (height) {
		queryParams.push(`h${Math.round(height)}`);
	}

	queryParams.push(cropFactor);
	queryParams.push(imageFormat);
	queryParams.push(`l${quality}`);
	queryParams.push(`e${cachedInDays}`);

	return queryParams.length === 0 ? '' : `=${queryParams.join('-')}`;
};

export const getSourceByMediaQueries = (mediaQueries, { src }) => {
	return (
		<>
			{mediaQueries.map((mediaQuery) => {
				const { width, height, media } = mediaQuery;
				return (
					<source
						key={media}
						srcSet={`${src}${getRisizeQuery({
							width,
							height,
							imageFormat: 'rw',
						})}, ${src}${getRisizeQuery({
							width: width * 2,
							height: height * 2,
							imageFormat: 'rw',
						})} 2x`}
						media={media}
						type="image/webp"
					/>
				);
			})}
		</>
	);
};

export default {};
