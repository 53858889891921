import React from 'react';
import Icon from '../icon';

import angle from '../svg/angle.large.sprite.svg';

const IconAngleLarge = (props) => {
	return <Icon {...props} sprite={angle} />;
};

IconAngleLarge.propTypes = Icon.propTypes;
IconAngleLarge.defaultProps = Icon.defaultProps;

export default IconAngleLarge;
