import React from 'react';
import Icon from '../icon';

import angle from '../svg/angle.medium.sprite.svg';

const IconAngleMedium = (props) => {
	return <Icon {...props} sprite={angle} />;
};

IconAngleMedium.propTypes = Icon.propTypes;
IconAngleMedium.defaultProps = Icon.defaultProps;

export default IconAngleMedium;
