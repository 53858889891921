import React from 'react';
import Icon from '../icon';

import angle from '../svg/angle.sprite.svg';

const IconAngle = (props) => {
	return <Icon {...props} sprite={angle} />;
};

IconAngle.propTypes = Icon.propTypes;
IconAngle.defaultProps = Icon.defaultProps;

export default IconAngle;
