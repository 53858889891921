import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import NLink from '@src/components/NLink';

import css from './style.module.scss';

const Address = (props) => {
	const {
		metros,
		mapLink,
		mix,
		mapLabel,
		address,
		state,
		landmark,
		onClickMap,
	} = props;

	const mapNode =
		mapLabel && onClickMap ? (
			<a
				role="link"
				tabIndex={0}
				onClick={onClickMap}
				className={css.mapLink}
			>
				{mapLabel}
			</a>
		) : null;

	return (
		<span
			className={cn(css.block, mix, {
				[css[`block_state_${state}`]]: !!state,
			})}
		>
			<span className={css.wrap}>
				{metros.length > 0 && (
					<span className={css.metros}>
						{metros.map((metro) => {
							const { name, colors } = metro;
							return (
								<span key={name} className={css.metro}>
									{colors.length > 0 && (
										<span className={css.colors}>
											{colors.map((clr) => {
												return (
													<i
														key={clr}
														className={css.color}
														style={{
															backgroundColor:
																clr,
														}}
													/>
												);
											})}
										</span>
									)}
									<span className={css.metroName}>
										{name}
									</span>
								</span>
							);
						})}
					</span>
				)}
				{address && (
					<span className={cn(css.address)}>
						<span
							className={cn(css.addressText, {
								[css.addressText_landmark]: !!landmark,
							})}
						>
							{address}
						</span>
						{landmark && (
							<span className={css.landmark}>{landmark}</span>
						)}
					</span>
				)}
				{mapLink ? <NLink href={mapLink}>{mapNode}</NLink> : mapNode}
			</span>
		</span>
	);
};

Address.propTypes = {
	metros: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			colors: PropTypes.arrayOf(PropTypes.string),
		}),
	),
	mapLink: PropTypes.string,
	mapLabel: PropTypes.string,
	address: PropTypes.string,
	landmark: PropTypes.string,
	state: PropTypes.oneOf(['map']),
	onClickMap: PropTypes.func,
	mix: PropTypes.string,
};

Address.defaultProps = {
	metros: [],
	mapLink: undefined,
	mapLabel: undefined,
	address: undefined,
	landmark: undefined,
	state: undefined,
	onClickMap: undefined,
	mix: undefined,
};

export default Address;
