import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './style.module.scss';

const HeartGlow = (props) => {
	const { active, mods, ...rest } = props;
	return (
		<span
			className={cn(css.block, mods, { [css.block_active]: active })}
			{...rest}
		>
			<svg
				viewBox="0 0 24 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={css.icon}
			>
				<path
					className={css.bg}
					d="M17.7031 1.04793C16.6083 0.909624 15.4976 1.08378 14.4921 1.55143C13.4866 2.01909 12.6247 2.76232 12.0003 3.70022C11.285 2.6251 10.2603 1.80907 9.07091 1.36749C7.88153 0.925912 6.58769 0.881115 5.37228 1.23944C4.15688 1.59776 3.0814 2.34107 2.29788 3.36429C1.51436 4.38751 1.06244 5.63888 1.006 6.9415C0.853497 10.5267 3.63753 13.0253 6.07755 15.4983C8.09553 17.547 10.9363 20.1554 11.752 20.909C11.8167 20.9677 11.8999 21 11.9861 21C12.0722 21 12.1554 20.9677 12.2202 20.909C13.0642 20.1554 15.9015 17.547 17.9194 15.502C20.3559 13.0253 23.1399 10.5157 22.9945 6.9415C22.9249 5.47423 22.3563 4.0792 21.3886 3.00139C20.4209 1.92359 19.116 1.23195 17.7031 1.04793Z"
				/>
				<path
					d="M17.7031 1.04793C16.6083 0.909624 15.4976 1.08378 14.4921 1.55143C13.4866 2.01909 12.6247 2.76232 12.0003 3.70022C11.285 2.6251 10.2603 1.80907 9.07091 1.36749C7.88153 0.925912 6.58769 0.881115 5.37228 1.23944C4.15688 1.59776 3.0814 2.34107 2.29788 3.36429C1.51436 4.38751 1.06244 5.63888 1.006 6.9415C0.853497 10.5267 3.63753 13.0253 6.07755 15.4983C8.09553 17.547 10.9363 20.1554 11.752 20.909C11.8167 20.9677 11.8999 21 11.9861 21C12.0722 21 12.1554 20.9677 12.2202 20.909C13.0642 20.1554 15.9015 17.547 17.9194 15.502C20.3559 13.0253 23.1399 10.5157 22.9945 6.9415C22.9249 5.47423 22.3563 4.0792 21.3886 3.00139C20.4209 1.92359 19.116 1.23195 17.7031 1.04793Z"
					strokeWidth="2"
					className={css.contour}
				/>
			</svg>
		</span>
	);
};

HeartGlow.propTypes = {
	active: PropTypes.bool,
	mods: PropTypes.string,
};

HeartGlow.defaultProps = {
	active: false,
	mods: undefined,
};

export default HeartGlow;
