export const DEFAULT_MEDIA_QUERIES = [
	{
		media: '(max-width: 959px)',
		width: 384,
		height: 256,
	},
	{
		media: '(min-width: 960px)',
		width: 384,
		height: 256,
	},
];
