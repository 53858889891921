import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import * as cacheSelectors from '@src/containers/Cache/selectors';
import * as cacheHooks from '@src/containers/Cache/hooks';
import * as sessionSelectors from '@src/containers/Session/selectors';
import { apigo } from '@src/libs/utils/fetcher/axios';

import { getRisizeQuery, getSourceByMediaQueries } from './helpers';

const Img = (props) => {
	const {
		src,
		alt,
		title,
		width,
		height,
		placeholder,
		forceVisibility,
		leadHeight,
		leadWidth,
		isImg,
		pictureClassName,
		children,
		className,
		sizes,
		mediaQueries,
		fallback,
		srcSet,
		tail,
		retinaFactor,
	} = props;

	const {
		fetchedData,
		isVisible,
		sensorOffset,
		handleChangeVisiblity,
		imgRef,
	} = useHooks({
		src,
		forceVisibility,
	});

	let imgNode = placeholder;

	const targetSrc = typeof src === 'string' ? src : fetchedData.src;

	if (targetSrc && (isVisible || forceVisibility)) {
		let computedWidth = width || fetchedData.width;
		let computedHeight = height || fetchedData.height;

		if (leadWidth) {
			computedHeight = Math.round(
				(leadWidth / computedWidth) * computedHeight,
			);
			computedWidth = leadWidth;
		} else if (leadHeight) {
			computedWidth = Math.round(
				(leadHeight / computedHeight) * computedWidth,
			);
			computedHeight = leadHeight;
		}

		let defaultQuery = getRisizeQuery({
			width: computedWidth,
			height: computedHeight,
		});

		let retinaQuery = getRisizeQuery({
			width: computedWidth * retinaFactor,
			height: computedHeight * retinaFactor,
		});

		let webpQuery = getRisizeQuery({
			width: computedWidth,
			height: computedHeight,
			imageFormat: 'rw',
		});

		let webpQuery2x = getRisizeQuery({
			width: computedWidth * retinaFactor,
			height: computedHeight * retinaFactor,
			imageFormat: 'rw',
		});

		const isGoogleImage =
			targetSrc.includes('googleusercontent') ||
			targetSrc.includes('nocdn.') ||
			targetSrc.includes('cdn.spotlyst');

		if (tail) {
			webpQuery = tail;
			webpQuery2x = tail;
			defaultQuery = tail;
			retinaQuery = tail;
		}

		if (!isGoogleImage) {
			webpQuery = '';
			webpQuery2x = '';
			defaultQuery = '';
			retinaQuery = '';
		}

		let sourceByMediaQueries;

		if (mediaQueries) {
			sourceByMediaQueries = getSourceByMediaQueries(mediaQueries, {
				src: targetSrc,
			});
		}

		if (typeof children === 'function') {
			imgNode = children(targetSrc, {
				getRisizeQuery,
				imgRef,
				isGoogleImage,
				width: computedWidth,
				height: computedHeight,
				alt,
				title,
				className,
			});
		} else {
			imgNode = isImg ? (
				<img
					ref={imgRef}
					src={`${targetSrc}${defaultQuery}`}
					srcSet={`${targetSrc}${retinaQuery} 2x`}
					alt={alt}
					title={title}
					className={className}
					width={computedWidth}
					height={computedHeight}
				/>
			) : (
				<picture className={pictureClassName}>
					{isGoogleImage && (
						<>
							{sourceByMediaQueries || (
								<source
									type="image/webp"
									srcSet={`${targetSrc}${webpQuery}, ${targetSrc}${webpQuery2x} 2x`}
								/>
							)}
							<img
								className={className}
								ref={imgRef}
								src={`${targetSrc}${defaultQuery}`}
								srcSet={`${targetSrc}${retinaQuery} 2x`}
								alt={alt}
								title={title}
								width={fallback?.width || computedWidth}
								height={fallback?.height || computedHeight}
								sizes={sizes}
							/>
						</>
					)}
					{!isGoogleImage && (
						<img
							className={className}
							ref={imgRef}
							src={`${targetSrc}`}
							alt={alt}
							title={title}
							width={fallback?.width || computedWidth}
							height={fallback?.height || computedHeight}
							sizes={sizes}
							srcSet={srcSet}
						/>
					)}
				</picture>
			);
		}
	}

	return (
		<VisibilitySensor
			onChange={handleChangeVisiblity}
			partialVisibility
			active={!isVisible}
			offset={sensorOffset}
		>
			{imgNode}
		</VisibilitySensor>
	);
};

const useHooks = ({ src, forceVisibility }) => {
	const imgRef = React.useRef();
	const imagesCache = useSelector(cacheSelectors.cachedImages);
	const isAllImagesForced = useSelector(
		sessionSelectors.isImagesPreloadForced,
	);
	const cachedImage = typeof src === 'number' ? imagesCache[src] || {} : {};
	const [sensorOffset, setSensorOffset] = React.useState(undefined);
	const [fetchedData, setFetchedData] = React.useState(cachedImage);
	const [isVisible, setVisibiblity] = React.useState(
		forceVisibility || isAllImagesForced,
	);

	const { cacheImageEntry } = cacheHooks.useActions();

	const handleChangeVisiblity = React.useCallback(
		(isVisibleFlag) => {
			setVisibiblity(isVisibleFlag);
		},
		[setVisibiblity],
	);

	const getImageDetails = React.useCallback((imageId) => {
		const url = `img/${imageId}`;
		return apigo.get(url);
	}, []);

	React.useEffect(() => {
		let promise;
		if (typeof src === 'number' && fetchedData.id !== src && isVisible) {
			promise = getImageDetails(src).then(({ data: res }) => {
				const data = res.entity[src];
				if (data) {
					const image = {
						id: data.id,
						src: data.url,
						width: data.width,
						height: data.height,
					};
					setFetchedData(image);
					cacheImageEntry({ data: image });
				}
			});
		}
		return () => {
			if (promise && promise.cancel) promise.cancel();
		};
	}, [
		isVisible,
		setFetchedData,
		fetchedData.id,
		src,
		cacheImageEntry,
		getImageDetails,
	]);

	React.useEffect(() => {
		setSensorOffset({
			bottom: -300,
			top: -300,
			left: -300,
			right: -300,
		});
	}, []);

	return {
		sensorOffset,
		isVisible,
		fetchedData,
		handleChangeVisiblity,
		imgRef,
	};
};

export const propTypes = {
	/**
	 * src изображения. Может быть числом для получения изображения через /api/go/img/:src
	 */
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	srcSet: PropTypes.string,
	/**
	 * alt изображения
	 */
	alt: PropTypes.string,
	/**
	 * title изображения
	 */
	title: PropTypes.string,
	/**
	 * Ширина изображения
	 */
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Высота изображения
	 */
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Приведение к требуемой высоте с сохранением исходных пропорций
	 */
	leadHeight: PropTypes.number,
	/**
	 * Приведение к требуемой ширине с сохранением исходных пропорций
	 */
	leadWidth: PropTypes.number,
	/**
	 * Элемент размещаемый в качестве заглушки при ленивой загрузке изображений
	 */
	placeholder: PropTypes.element,
	/**
	 * Флаг форсирующий ленвую загрузку изображения, независимо от вьюпорта
	 */
	forceVisibility: PropTypes.bool,
	/**
	 * Флаг для возврата img вместо picture
	 */
	isImg: PropTypes.bool,
	/**
	 * Доп. классы для тега picture
	 */
	pictureClassName: PropTypes.string,

	retinaFactor: PropTypes.number,

	/**
	 * Функция для кастомного рендеринга полученного src изображения
	 * @callback
	 * @param {string} src полученный по результату загрузки
	 * @param {api} componentApi апи для генерации подрезанных src от google
	 */
	children: PropTypes.oneOfType([PropTypes.func]),
	className: PropTypes.string,
	/**
	 * Аттрибут `sizes` для `<picture>`.
	 */
	sizes: PropTypes.string,
	mediaQueries: PropTypes.arrayOf(
		PropTypes.shape({
			media: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
			height: PropTypes.number.isRequired,
		}),
	),
	fallback: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	tail: PropTypes.string,
};

export const defaultProps = {
	alt: '',
	title: '',
	width: undefined,
	height: undefined,
	srcSet: undefined,
	placeholder: (
		<picture>
			<img
				alt=""
				width="1"
				height="1"
				style={{ minWidth: 1, minHeight: 1 }}
			/>
		</picture>
	),
	forceVisibility: false,
	leadHeight: undefined,
	leadWidth: undefined,
	isImg: false,
	pictureClassName: undefined,
	children: undefined,
	className: undefined,
	sizes: undefined,
	mediaQueries: undefined,
	fallback: undefined,
	tail: undefined,
	retinaFactor: 1.5,
};

Img.propTypes = propTypes;
Img.defaultProps = defaultProps;

export default Img;
