import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { StarSmallIcon } from '@src/components/Icon';

import css from './style.module.scss';

const iconsSet = {
	ratingStar: (props) => <StarSmallIcon width={10} height={10} {...props} />,
};

const PropTag = (props) => {
	const { bgColor, size, text, border, mix, leftIcon } = props;
	const LeftIcon = leftIcon ? iconsSet[leftIcon] : null;
	return (
		<div
			className={cn(css.block, mix, {
				[css[`block_bg_${bgColor}`]]: !!bgColor,
				[css[`block_size_${size}`]]: !!size,
				[css[`block_border_${border}`]]: !!border,
			})}
		>
			{LeftIcon && <LeftIcon className={css.leftIcon} />}
			{text && <span className={css.text}>{text}</span>}
		</div>
	);
};

PropTag.propTypes = {
	mix: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	border: PropTypes.oneOf(['gray']),
	bgColor: PropTypes.oneOf(['gray', 'green', 'white']),
	size: PropTypes.oneOf(['small']),
	leftIcon: PropTypes.oneOf(Object.keys(iconsSet)),
};

PropTag.defaultProps = {
	mix: undefined,
	bgColor: undefined,
	size: undefined,
	text: undefined,
	border: undefined,
	leftIcon: undefined,
};

export default PropTag;
