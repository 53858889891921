import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconAngleSmall from '@src/components/Icon/set/IconAngleSmall';
import IconAngleMedium from '@src/components/Icon/set/IconAngleMedium';
import IconAngleLarge from '@src/components/Icon/set/IconAngleLarge';
import IconComponent from '@src/components/Icon/icon';

import css from './style.module.scss';

const BtnArrow = (props) => {
	const {
		type,
		direction,
		disabled,
		iconProps,
		className,
		onClick,
		onMouseEnter,
		onMouseLeave,
	} = props;

	let Icon;

	switch (type) {
		case 'small':
			Icon = IconAngleSmall;
			break;
		case 'medium':
			Icon = IconAngleMedium;
			break;
		case 'large':
			Icon = IconAngleLarge;
			break;
		default:
			Icon = IconAngleSmall;
	}

	return (
		<span
			tabIndex={0}
			role="button"
			className={cn(
				css.block,
				{
					[css[`block_${type}`]]: !!type,
					[css.block_disabled]: disabled,
				},
				className,
			)}
			onClick={disabled ? undefined : onClick}
			onMouseLeave={onMouseLeave}
			onMouseEnter={onMouseEnter}
		>
			<Icon
				{...iconProps}
				className={cn(
					css.icon,
					{
						[css[`icon_direction_${direction}`]]: !!direction,
					},
					iconProps?.className,
				)}
			/>
		</span>
	);
};

BtnArrow.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.oneOf(['plain', 'small', 'medium', 'large']),
	direction: PropTypes.oneOf(['left', 'right']),
	disabled: PropTypes.bool,
	iconProps: PropTypes.shape(IconComponent.propTypes),
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
};

BtnArrow.defaultProps = {
	type: 'plain',
	direction: 'left',
	disabled: false,
	iconProps: undefined,
	className: undefined,
	onClick: undefined,
	onMouseEnter: undefined,
	onMouseLeave: undefined,
};

export default BtnArrow;
