import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Img from '@src/components/Img';
import NLink from '@src/components/NLink';
import Address from '@src/components/Address';
import PropTag from '@src/components/PropTag';
import IconLink from '@src/components/IconLink';
import MediaSlider from '@src/components/MediaSlider';
import HeartGlow from '@src/components/HeartGlow';

import { DEFAULT_MEDIA_QUERIES } from './constants';
import css from './style.module.scss';

const Space = (props) => {
	const {
		id,
		preview,
		name,
		category,
		description,
		linkTo,
		imgs,
		tags,
		isLiked,
		address,
		shortAddress,
		onClickHeart,
		moreLabel,
		iconLinkState,
		adaptive,
		state,
		plateColor,
		appearance,
		infoTag,
		mediaQueries,
		fallback,
		onClickMap,
		imgWidth,
		imgHeight,
		moreTarget,
		isLazy,
	} = props;

	const handleMapClick = React.useCallback(() => {
		if (onClickMap) {
			onClickMap({ id });
		}
	}, [id, onClickMap]);

	const moreLink = moreLabel ? (
		<IconLink
			target={moreTarget}
			href={linkTo}
			rightIcon="pointer"
			state={iconLinkState}
		>
			{moreLabel}
		</IconLink>
	) : null;

	return (
		<div
			className={cn(css.block, {
				[css[`block_state_${state}`]]: !!state,
				[css[`block_adapt_${adaptive}`]]: !!adaptive,
				[css[`block_appearance_${appearance}`]]: !!appearance,
			})}
		>
			<div
				className={cn(css.wrap, {
					[css[`wrap_bg_${plateColor}`]]: !!plateColor,
				})}
			>
				<div className={css.preview}>
					{onClickHeart && (
						<HeartGlow
							mods={cn(css.favour, { active: isLiked })}
							active={isLiked}
							onClick={() => onClickHeart(isLiked, id)}
						/>
					)}
					{infoTag && <span className={css.infoTag}>{infoTag}</span>}
					{preview && (
						<div className={css.imageWrap}>
							<Img
								{...preview}
								width={imgWidth || preview.width}
								height={imgHeight || preview.height}
								className={cn(preview.className, css.image)}
								placeholder={
									<span className={css.placeholder} />
								}
							/>
							{linkTo && (
								<NLink href={linkTo}>
									<a
										className={css.previewLink}
										target="_blank"
									/>
								</NLink>
							)}
						</div>
					)}
					{imgs.length > 0 && (
						<MediaSlider
							mix={css.slider}
							imgs={imgs}
							width={imgWidth}
							height={imgHeight}
							mediaQueries={mediaQueries}
							fallback={fallback}
							isLazy={isLazy}
							linkTo={linkTo}
						/>
					)}
					{!preview?.src && imgs.length === 0 && (
						<>
							<span className={css.placeholder} />
							{linkTo && (
								<NLink href={linkTo}>
									<a
										className={css.previewLink}
										target="_blank"
									/>
								</NLink>
							)}
						</>
					)}
				</div>
				<div className={css.content}>
					{(name || category) && (
						<NLink href={linkTo}>
							<a className={css.title} target="_blank">
								<span className={css.name}>{name}</span>
								<span className={css.category}>{category}</span>
							</a>
						</NLink>
					)}
					<div className={css.tags}>
						{tags.map((tag) => {
							return (
								<PropTag
									key={tag.text}
									{...tag}
									mix={cn(tag.mix, css.tag)}
								/>
							);
						})}
					</div>
					{address && (
						<div className={css.address}>
							<Address
								onClickMap={onClickMap ? handleMapClick : null}
								mapLabel={t('На карте')}
								{...address}
							/>
						</div>
					)}
					<div className={css.desc}>{description}</div>
					<div className={css.shortAddress}>{shortAddress}</div>
					{moreLabel && (
						<div className={css.moreHolder}>
							{linkTo ? (
								<NLink href={linkTo}>{moreLink}</NLink>
							) : (
								moreLink
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

Space.propTypes = {
	id: PropTypes.number,
	state: PropTypes.oneOf(['medium', 'large', 'tiny']),
	adaptive: PropTypes.oneOf(['mapLayer', 'profileMore']),
	linkTo: PropTypes.string,
	name: PropTypes.string,
	category: PropTypes.string,
	description: PropTypes.string,
	shortAddress: PropTypes.string,
	moreLabel: PropTypes.string,
	iconLinkState: IconLink.propTypes.state,
	isLiked: PropTypes.bool,
	onClickHeart: PropTypes.func,
	preview: PropTypes.shape(Img.type.propTypes),
	imgs: PropTypes.arrayOf(PropTypes.shape(Img.type.propTypes)),
	mediaQueries: Img.type.propTypes.mediaQueries,
	fallback: Img.type.propTypes.fallback,
	tags: PropTypes.arrayOf(PropTypes.shape(PropTag.type.propTypes)),
	address: PropTypes.shape(Address.type.propTypes),
	appearance: PropTypes.oneOf(['firstId', 'adv']),
	plateColor: PropTypes.oneOf(['gray', 'plain']),
	infoTag: PropTypes.string,
	onClickMap: PropTypes.func,
	imgWidth: Img.type.propTypes.width,
	imgHeight: Img.type.propTypes.height,
	moreTarget: PropTypes.string,
	isLazy: PropTypes.bool,
};

Space.defaultProps = {
	id: undefined,
	linkTo: undefined,
	state: undefined,
	adaptive: undefined,
	iconLinkState: undefined,
	name: undefined,
	category: undefined,
	description: undefined,
	moreLabel: undefined,
	preview: undefined,
	address: undefined,
	onClickHeart: undefined,
	shortAddress: undefined,
	isLiked: false,
	imgs: [],
	tags: [],
	mediaQueries: DEFAULT_MEDIA_QUERIES,
	fallback: {
		width: 384,
		height: 256,
	},
	appearance: undefined,
	infoTag: undefined,
	onClickMap: undefined,
	imgWidth: undefined,
	imgHeight: undefined,
	plateColor: undefined,
	moreTarget: undefined,
	isLazy: true,
};

export default Space;
