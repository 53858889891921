import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../reducers';

export const useActions = () => {
	const dispatch = useDispatch();

	const resetState = useCallback(() => {
		dispatch(actions.reset());
	}, [dispatch]);

	const cacheImageEntry = useCallback(
		(params) => {
			dispatch(actions.cacheImageEntry(params));
		},
		[dispatch],
	);

	return {
		resetState,
		cacheImageEntry,
	};
};

export default useActions;
