import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './style.module.scss';

const Dots = (props) => {
	const { total, current, defaultCurrent, edgeQtty, onChange, state } = props;

	const [curr, setCurrent] = React.useState(defaultCurrent);

	const handleChange = React.useCallback(
		(i) => {
			if (onChange) {
				onChange(i);
			}
			if (current === undefined) {
				setCurrent(i);
			}
		},
		[onChange, current],
	);

	const activeIndex = current === undefined ? curr : current;
	const isLeftEdge = activeIndex < edgeQtty;
	const isRightEdge = activeIndex >= total - edgeQtty;
	const smallRoundSize = 4;
	const bigRoundSize = 8;
	const roundMargin = 4;
	// const containerWidth = 53;
	const containerWidth = 50;

	const totalWidth =
		(total - 4) * smallRoundSize +
		bigRoundSize * 4 +
		roundMargin * (total - 1);

	const maxOffset = containerWidth - totalWidth + 2;

	let leftOffset = 0;

	if (total > 5) {
		if (activeIndex > edgeQtty) {
			leftOffset = `${-8 * (activeIndex - edgeQtty)}px`;
		}

		if (isLeftEdge) {
			leftOffset = 0;
		}

		if (isRightEdge) {
			leftOffset = `${maxOffset}px`;
		}
	}

	return (
		<span
			className={cn(css.block, {
				[css[`block_state_${state}`]]: !!state,
				[css.block_small]: !isRightEdge && !isLeftEdge,
			})}
		>
			<span className={css.wrap}>
				<span className={css.items} style={{ left: leftOffset }}>
					{new Array(total).fill(total).map((_, i) => {
						let isActive = false;
						let isSubActive = false;

						if (isLeftEdge && i <= edgeQtty) isActive = true;

						if (isRightEdge && i >= total - edgeQtty - 1) {
							isActive = true;
						}

						if (activeIndex === i) isActive = true;

						if (!isLeftEdge && !isRightEdge) {
							isSubActive =
								i + 1 === activeIndex || i - 1 === activeIndex;
						}

						if (isLeftEdge && i === edgeQtty + 1)
							isSubActive = true;

						if (isRightEdge && i === total - edgeQtty - 2)
							isSubActive = true;

						return (
							<span
								// eslint-disable-next-line react/no-array-index-key
								key={i}
								role="button"
								tabIndex={0}
								onClick={() => handleChange(i)}
								className={cn(css.item, {
									[css.item_active]: isActive,
									[css.item_subActive]: isSubActive,
									[css.item_transparent]:
										(isLeftEdge || isRightEdge) &&
										activeIndex !== i,
								})}
							>
								{i + 1}
							</span>
						);
					})}
				</span>
			</span>
		</span>
	);
};

Dots.propTypes = {
	total: PropTypes.number.isRequired,
	edgeQtty: PropTypes.number,
	current: PropTypes.number,
	defaultCurrent: PropTypes.number,
	onChange: PropTypes.func,
	state: PropTypes.oneOf(['purple']),
};

Dots.defaultProps = {
	defaultCurrent: 0,
	edgeQtty: 2,
	current: undefined,
	onChange: undefined,
	state: undefined,
};

export default Dots;
