import React from 'react';
import { useDispatch } from 'react-redux';
import { ANALYTICS_GOAL, useReachGoal } from '@src/libs/hooks/useReachGoal';
import { FAVOURITE_TYPES } from '../constants';
import { actions as sessionActions } from '../reducers';

const useFavourites = () => {
	const dispatch = useDispatch();
	const { reachGoal } = useReachGoal();
	const toggleSpaceFavour = React.useCallback(
		(space, { isRemove } = {}) => {
			const item = { id: space.id };
			reachGoal(ANALYTICS_GOAL.click_add_favorites_btn);
			dispatch(
				sessionActions.toggleFavouriteItem({
					item,
					type: FAVOURITE_TYPES.SPACE,
					isRemove,
				}),
			);
		},
		[dispatch, reachGoal],
	);

	const addSpaceToFavour = React.useCallback(
		(item) => {
			toggleSpaceFavour(item, { isRemove: false });
		},
		[toggleSpaceFavour],
	);

	const removeSpaceFromFavour = React.useCallback(
		(item) => {
			toggleSpaceFavour(item, { isRemove: true });
		},
		[toggleSpaceFavour],
	);

	return {
		addSpaceToFavour,
		removeSpaceFromFavour,
	};
};

export default useFavourites;
