/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide, Swiper } from 'swiper/react';
import { throttle } from 'lodash-es';

import Img from '@src/components/Img';
import Dots from '@src/components/Dots';
import NLink from '@src/components/NLink';
import BtnArrow from '@src/components/BtnArrow';

import css from './style.module.scss';

const MediaSlider = (props) => {
	const {
		imgs,
		mix,
		mediaQueries,
		fallback,
		width,
		height,
		linkTo,
		placeholder,
		isSlideMoveHandler,
		isLazy,
	} = props;

	const slider = React.useRef(null);
	const [isLazyActive, setLazyActive] = React.useState(!isLazy);
	const [activeIndex, setActiveIndex] = React.useState(0);

	const handleDotClick = React.useCallback((i) => {
		setActiveIndex(i);
		slider.current?.slideTo(i);
	}, []);

	const slideLeft = () => {
		slider.current?.slidePrev();
	};
	const slideRight = () => {
		slider.current?.slideNext();
	};

	const lazyImgs = React.useMemo(() => {
		return !isLazyActive && imgs[0] ? [imgs[0]] : imgs;
	}, [imgs, isLazyActive]);

	const [isMouseIn, setMouseIn] = React.useState(false);

	const handleMouseEL = React.useCallback((e) => {
		setMouseIn(e.type === 'mouseenter');
	}, []);

	const handleSwiper = React.useCallback(
		(swiper) => {
			slider.current = swiper;

			// Обеспечивает смену точек в процессе свайпа
			const handleMove = throttle((s) => {
				s.slidesGrid.some((p, i) => {
					if (p >= s.translate * -1) {
						const safeIndex = Math.min(
							Math.max(i - 1, 0),
							s.slidesGrid.length - 1,
						);
						setActiveIndex(safeIndex);
						return true;
					}
					return false;
				});
			}, 100);

			if (isSlideMoveHandler) {
				swiper.on('sliderMove', handleMove);
			}
		},
		[slider, isSlideMoveHandler],
	);

	const handleSlideChange = React.useCallback((swiper) => {
		setActiveIndex(swiper.activeIndex);
	}, []);

	const handleLazyInit = React.useCallback(() => {
		setLazyActive(true);
	}, []);

	if (imgs.length === 0) {
		return null;
	}

	const slideNodes = lazyImgs.map((img, i) => {
		const imgNode = (
			<Img
				key={i}
				{...img}
				className={cn(img.className, css.img)}
				width={width || img.width}
				height={height || img.height}
				mediaQueries={mediaQueries}
				fallback={fallback}
				forceVisibility
				placeholder={
					placeholder || <span className={css.placeholder} />
				}
			/>
		);

		const withLink = linkTo ? (
			<NLink key={i} href={linkTo}>
				<a target="_blank" className={css.link}>
					{imgNode}
				</a>
			</NLink>
		) : (
			imgNode
		);

		return isLazyActive ? (
			<SwiperSlide key={i} className={css.slide}>
				{withLink}
			</SwiperSlide>
		) : (
			withLink
		);
	});

	return (
		<div
			className={cn(css.block, mix)}
			onMouseEnter={!isLazyActive ? handleLazyInit : null}
			onTouchMove={!isLazyActive ? handleLazyInit : null}
		>
			<div className={css.wrap}>
				<div className={css.slideWrap}>
					{isLazyActive ? (
						<Swiper
							cssMode
							initialSlide={activeIndex}
							simulateTouch={false}
							onSwiper={handleSwiper}
							onSlideChange={handleSlideChange}
						>
							{slideNodes}
						</Swiper>
					) : (
						slideNodes
					)}
				</div>

				{imgs.length > 1 && (
					<>
						{(activeIndex > 0 || isMouseIn) && (
							<BtnArrow
								type="small"
								direction="left"
								onClick={slideLeft}
								className={cn(css.arrows, css.arrows_left, {
									[css['arrows_partially-visible']]:
										activeIndex === 0 && isMouseIn,
								})}
								onMouseEnter={handleMouseEL}
								onMouseLeave={handleMouseEL}
							/>
						)}
						{(activeIndex + 1 < imgs.length || isMouseIn) && (
							<BtnArrow
								type="small"
								s
								direction="right"
								onClick={slideRight}
								className={cn(css.arrows, css.arrows_right, {
									[css['arrows_partially-visible']]:
										activeIndex + 1 === imgs.length &&
										isMouseIn,
								})}
								onMouseEnter={handleMouseEL}
								onMouseLeave={handleMouseEL}
							/>
						)}
						<div className={css.dots}>
							<Dots
								current={activeIndex}
								total={imgs.length}
								onChange={handleDotClick}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

MediaSlider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	imgs: PropTypes.arrayOf(PropTypes.shape(Img.type.propTypes)),
	mix: PropTypes.string,
	mediaQueries: Img.type.propTypes.mediaQueries,
	fallback: Img.type.propTypes.fallback,
	width: Img.type.propTypes.width,
	height: Img.type.propTypes.height,
	isLazy: PropTypes.bool,
	linkTo: PropTypes.string,
	placeholder: PropTypes.node,
	isSlideMoveHandler: PropTypes.bool,
};

MediaSlider.defaultProps = {
	children: undefined,
	mix: undefined,
	mediaQueries: undefined,
	fallback: undefined,
	imgs: [],
	width: undefined,
	height: undefined,
	isLazy: true,
	linkTo: undefined,
	placeholder: undefined,
	isSlideMoveHandler: false,
};

export default MediaSlider;
